import React from "react";
import { Link } from "gatsby";

const GoToSection = ({ title, description, btnPath, btnText }) => (
  <div className="bg-white">
    <div className="max-w-7xl mx-auto px-4 sm:pb-8 sm:px-6 lg:px-8">
      <div className="text-center">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
          {title}
        </h2>
        <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500 text-center">
          {description}
        </p>
        <Link
          to={btnPath}
          className="mt-6 inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-amber-500 hover:bg-amber-600"
        >
          {btnText}
        </Link>
      </div>
    </div>
  </div>
);

export default GoToSection;
