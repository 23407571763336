import * as React from "react";
import {
  CogIcon,
  CubeTransparentIcon,
  ServerIcon,
} from "@heroicons/react/solid";
// import { InboxIcon, SparklesIcon } from "@heroicons/react/outline";

import PageTitle from "../components/elements/PageTitle";
import Header from "../components/sections/Header";
import ActionCallSection from "../components/sections/ActionCallSection";
import ServicesSection from "../components/sections/ServicesSection";
import ServiceItem from "../components/elements/ServiceItem";
import Footer from "../components/sections/Footer";
import AboutModelsSection from "../components/sections/AboutModelsSection";
import AboutModelItem from "../components/elements/AboutModelItem";
import FeaturesSection from "../components/sections/FeaturesSection";
import { InboxIcon } from "@heroicons/react/outline";
// import FeaturesSection from "../components/sections/FeaturesSection";
// import photo from "../images/posplus_blue.jpg";
import shop from "../images/crforweb.jpg";
// import schema from "../images/forwebsmall.png";
import douglas from "../images/douglas.jpg";
import GoToSection from "../components/sections/GoToSection";

const IndexSePage = () => (
  <div className="bg-white">
    <PageTitle pageTitle={"Hem"} lang={"se"} description={""} />
    <Header lang={"se"} />
    <ActionCallSection
      mainTitle={"posplus"}
      highlightedText={"kontrollenhet"}
      description={
        "Posplus är, det kompakta formatet till trots, både marknadens kraftfullaste och  mest kompatibla kontrollenhet."
      }
      brightName={
        "VÄLKOMMEN TILL POSPLUS –  MARKNADSLEDANDE KONTROLLENHET FÖR FULL KONTROLL PÅ KASSAN"
      }
    />
    <FeaturesSection
      FirstIcon={InboxIcon}
      firstTitle={`Nästa generation av kontrollenheter`}
      firstDescription={`Vi implementerade ett nytt sätt att behandla skatte data, den senaste generationens hårdvara är en mikrodator som appliceras lokalt. Den signerar och lagrar skatte data samt replikerar data till en certifierad server. Hårdvaran kan anslutas direkt i kassaregistret alternativt till det lokala nätverket och stödja en eller flera kassaregister via kabel eller WiFi.`}
      firstImgName={shop}
      firstBtnText={`Kontakta oss`}
      firstBtnPath="/se/contacts"
      firstDetailsText={`Skatteverket behöver inte längre besöka ditt försäljningsställe för att ladda ner skatteuppgifter.`}
      firstDetailsImgName={douglas}
      firstDetailsAuthor={`Douglas Oest, Axena Company Service AB, CEO`}
      secondBtnText={`Läs mer...`}
      secondBtnPath="/se/models/buffering-unit"
      /*SecondIcon={SparklesIcon}
      // secondTitle - look at FeaturesSection
      secondDescription={`Fiscal data is being sent to the server in REAL TIME. All fiscal data is replicated and kept on BOTH SIDES: Buffering Unit and Control Server. 
      
      If the internet connection is lost, the Buffering Unit continues signing and buffers fiscal data for 48 hours, waits until the internet connection is restored, then sends data to the Control Server
`}
      secondImgName={schema}
      secondBtnText={`Contact us`}
      secondBtnPath="/en/contacts"
    */
    />
    <ServicesSection
      sectionTitle={""}
      title={"Då tekniken blir mindre"}
      description={`Blir även den tekniska delen större, nu finns våra kontrollenheter med lagring från 32 000 000+ poster med minnen från 8Gb upp till 128Gb kapacitet.`}
      showMoreText={"Show more services"}
      showMorePath={"/models/"}
    >
      <ServiceItem
        name="Smidig.
        Multikompatibel"
        description={`PosPlus kan anpassas och integreras enkelt mot dina befintliga kassalösningar. Ingen extern strömkälla behövs och det kompakta formatet är perfekt för mobila lösningar.`}
        IconImg={CubeTransparentIcon}
      />
      <ServiceItem
        name="Kraftfull.
        Oöverträffad kapacitet"
        description={`PosPlus lagrar från 32 miljoner+ kvitton och kan registrera nytt organisations nummer/kassaid 1000 gånger och beställas för upp till 9000  användare. Utomhus klarar den temeperaturer ner mot -20C. Det betyder att PosPlus med god marginal klarar de mest krävande  miljöer och behov.`}
        IconImg={CogIcon}
      />
      <ServiceItem
        name="Trygg.
        Dubbelt certifierad"
        description={`Som namnet antyder är PosPlus certifierad och godkänd enligt Skatteverkets föreskrifter. Dessutom är PosPlus en av få kontrollenheter som även kvalificerar sig för branschens egen certifiering Kassacertifiering.se`}
        IconImg={ServerIcon}
      />
    </ServicesSection>
    <AboutModelsSection
      sectionTitle="Modeller"
      title="Välj styrenhet till ditt företag"
      description="Från MVP-utveckling till support efter release, vi hjälper våra kunder att ta ut sina produkter på marknaden snabbare och utan hinder på vägen."
    >
      <AboutModelItem
        name="Modell A"
        description={`Modell A är avsedd för en kassa. Kopplas in via kassans USB port.`}
        linkText="LÄS MER"
        linkPath="/se/models/a"
      />
      <AboutModelItem
        name="Modell C"
        description={`Modell C är avsedd för att kopplas till en central server, lokalt eller via internet och på så sätt fungera som kontrollenhet för en mängd kassor.`}
        linkText="LÄS MER"
        linkPath="/se/models/c"
      />
      <AboutModelItem
        name="Buffering Unit"
        description={`Skatte data skickas till servern i realtid. All skatte information replikeras och förvaras i både Buffert enhet och Kontrollsystem servern.`}
        linkText="LÄS MER"
        linkPath="/se/models/buffering-unit"
      />
    </AboutModelsSection>
    <GoToSection
      title="Molnkontrollenheter"
      description="Prova ett nytt sätt att hantera dina ekonomidata - skicka den bara till molnservern. Klicka nedan för att veta mer"
      btnPath="https://srv4pos.com/"
      btnText="Gå till srv4pos.com"
    />

    <Footer lang={"se"} />
  </div>
);

export default IndexSePage;
