import * as React from "react";

const AboutModelsSection = ({ sectionTitle, title, description, children }) => (
  <div className="bg-gray-900 mt-16 sm:mt-20">
    <div className="pt-12 px-4 sm:px-6 lg:px-8 lg:pt-20">
      <div className="text-center">
        <h2 className="text-base leading-6 font-semibold text-gray-300 uppercase tracking-wider">
          {sectionTitle}
        </h2>
        <p className="mt-2 text-3xl font-extrabold text-white">
          {title}
        </p>
        <p className="mt-3 max-w-4xl mx-auto text-xl text-gray-300 sm:mt-5">
          {description}
        </p>
      </div>
    </div>
    <div className="mt-16 bg-white pb-8 lg:mt-20 lg:pb-16">
      <div className="relative z-0">
        <div className="absolute inset-0 h-5/6 bg-gray-900 lg:h-2/3"></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div
            className={`relative grid gap-8 lg:gap-16 lg:grid-flow-col md:grid-cols-${React.Children.count(
              children
            )}`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AboutModelsSection;
