import * as React from "react";
import { Link } from "gatsby";
import { ChevronRightIcon } from "@heroicons/react/outline";

const ServiceItem = ({
  name,
  IconImg,
  description,
  showMoreText,
  showMorePath,
}) => (
  <div key={name} className="pt-6 relative">
    <div
      className={`flow-root h-full bg-gray-50 rounded-lg px-6 ${
        showMoreText ? "pb-20" : "pb-8"
      }`}
    >
      <div className="-mt-6">
        <div>
          <span className="inline-flex items-center justify-center p-3 bg-slate-800 rounded-md shadow-lg">
            <IconImg className="h-6 w-6 text-white" aria-hidden="true" />
          </span>
        </div>
        <h3 className="mt-8 text-lg font-medium text-[#06293d] tracking-tight">
          {name}
        </h3>
        <p className="mt-5 text-base text-gray-500">{description}</p>
      </div>
    </div>
    {showMoreText && (
      <Link
        to={showMorePath}
        className="absolute w-max bottom-4 left-[50%] translate-x-[-50%] inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-600"
      >
        {showMoreText}{" "}
        <ChevronRightIcon
          className="flex-shrink-0 w-6 h-6 text-amber-300"
          aria-hidden="true"
        />
      </Link>
    )}
  </div>
);
export default ServiceItem;
