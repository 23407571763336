import * as React from "react";
import { CheckIcon } from "@heroicons/react/outline";
import { Link } from "gatsby";

const AboutModelItem = ({ name, description, linkText, linkPath }) => (
  <div
    key={name}
    className="flex flex-col rounded-lg shadow-lg overflow-hidden border-2 border-solid border-gray-900"
  >
    <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
      <div className="justify-center mt-4 flex items-baseline text-3xl font-bold">
        {name}
      </div>
    </div>
    <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
      <div className="space-y-4">
        <div className="flex items-start">
          <div className="flex-shrink-0">
            <CheckIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
          </div>
          <p className="ml-3 text-base text-gray-700">{description}</p>
        </div>
      </div>
      <div className="rounded-md shadow">
        <Link
          to={linkPath}
          className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-amber-500 hover:bg-amber-600"
        >
          {linkText}
        </Link>
      </div>
    </div>
  </div>
);

export default AboutModelItem;
