import React from "react";
import { Link } from "gatsby";

const FeaturesSection = ({
  FirstIcon,
  firstTitle,
  firstDescription,
  firstImgName,
  firstBtnText,
  firstBtnPath,
  firstDetailsText,
  firstDetailsImgName,
  firstDetailsAuthor,
  SecondIcon,
  secondTitle,
  secondDescription,
  secondImgName,
  secondBtnText,
  secondBtnPath,
  thirdBtnText,
  thirdBtnPath,
}) => (
  <div className="relative bg-white pt-16 sm:pt-20 overflow-hidden">
    <div className="relative">
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
        <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
          <div>
            {FirstIcon && (
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-slate-800">
                  <FirstIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div>
            )}
            <div className="mt-6">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                {firstTitle}
              </h2>
              <p className="mt-4 text-lg text-gray-500">{firstDescription}</p>
              {(firstBtnText || secondBtnText) && (
                <div className="mt-6 space-x-4">
                  <Link
                    to={firstBtnPath}
                    className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-amber-500 hover:bg-amber-600"
                  >
                    {firstBtnText}
                  </Link>
                  {secondBtnText && (
                    <Link
                      to={secondBtnPath}
                      className="inline-flex px-4 py-2 border-2 border-amber-500 text-base text-amber-500 font-medium rounded-md shadow-sm bg-white hover:bg-amber-100"
                    >
                      {secondBtnText}
                    </Link>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="mt-8 border-t border-gray-200 pt-6">
            <blockquote>
              {firstDetailsText && (
                <div>
                  <p className="text-base text-gray-500">
                    &ldquo;{firstDetailsText}&rdquo;
                  </p>
                </div>
              )}
              <footer className="mt-3">
                <div className="flex items-center space-x-3">
                  {firstDetailsImgName && (
                    <div className="flex-shrink-0">
                      <img
                        className="h-6 w-6 rounded-full"
                        src={firstDetailsImgName}
                        alt=""
                      />
                    </div>
                  )}
                  {firstDetailsAuthor && (
                    <div className="text-base font-medium text-gray-700">
                      {firstDetailsAuthor}
                    </div>
                  )}
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
        {firstImgName && (
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src={firstImgName}
                alt="Inbox user interface"
              />
            </div>
          </div>
        )}
      </div>
    </div>
    {(SecondIcon || secondDescription) && (
      <div className="mt-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
            <div>
              {SecondIcon && (
                <div>
                  <span className="h-12 w-12 rounded-md flex items-center justify-center bg-slate-800">
                    <SecondIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </div>
              )}
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  {/* secondTitle */}
                  Can operate multiple cash registers out&#8209;of&#8209;box via
                  Wi&#8209;Fi
                </h2>
                {secondDescription && (
                  <p className="mt-4 text-lg text-gray-500">
                    {secondDescription}
                  </p>
                )}
                {thirdBtnText && (
                  <div className="mt-6">
                    <Link
                      to={thirdBtnPath}
                      className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-amber-500 hover:bg-amber-600"
                    >
                      {thirdBtnText}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
          {secondImgName && (
            <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
              <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                  src={secondImgName}
                  alt="Customer profile user interface"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    )}
  </div>
);

export default FeaturesSection;
