import * as React from "react";

const ServicesSection = ({
  sectionTitle,
  title,
  description,
  children,
}) => (
  <div className="relative bg-white pt-16 sm:pt-20">
    <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
      <h2 className="text-base font-semibold tracking-wider text-amber-600 uppercase">
        {sectionTitle}
      </h2>
      <p className="mt-2 text-3xl font-extrabold text-slate-800 tracking-tight sm:text-4xl">
        {title}
      </p>
      <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500 text-center">
        {description}
      </p>
      <div className="mt-12">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {children}
        </div>
      </div>
    </div>
  </div>
);
export default ServicesSection;
